import React, { useEffect, useState } from "react";
import "./DashboardView.css";
import "./SellPointsView.css"
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import TopLastGraphic from "../../../Components/Graphics/TopLastGraphic";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllProducts from "./AllProducts";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const SellPointsView = () => {
  const { hotelData, datosDashboardNew, rol } = useAppContext();
  const graphArr = Array.from({ length: 100 }, (_, index) => index + 1);
  const [allProductsVisible, setAllProductsVisible] = useState(false);
  const [sellPointObjectives, setSellPointObjectives] = useState(null);
  const [graphToShow, setGraphToShow] = useState("top");
  const [allScreen, setAllScreen] = useState(false);
  const [sellPointList, setSellPointList] = useState(null);
  const [sellPointSelected, setSellPointSelected] = useState(null)

  const changeWord = useLanguages()

  const getObjective = (periodeObjective, sellPointName) => {
    const sellPointObjective = periodeObjective.find(
      (item) => item.sellPointName === sellPointName
    );

    return sellPointObjective.objective;
  };
  useEffect(() => {
    if (datosDashboardNew && datosDashboardNew.pointOfSaleIncome) {
      const sellPoints = datosDashboardNew.pointOfSaleIncome;
      const monthObjective = datosDashboardNew.pointOfSaleMonthObjective;
      const yearObjectives = datosDashboardNew.pointOfSaleYearObjective;
      const periodicity = datosDashboardNew.periodicity;
      const sellPointObjectivesArray = [];
      for (const sellPoint of sellPoints) {
        const sellPointName = sellPoint.pointOfSaleName;
        const sellPointIncome = sellPoint.pointOfSaleIncome;

        const objective =
          periodicity !== "100"
            ? getObjective(monthObjective, sellPoint.pointOfSaleName)
            : getObjective(yearObjectives, sellPoint.pointOfSaleName);
        sellPointObjectivesArray.push({
          sellPointName: sellPointName,
          sellPointIncome: sellPointIncome,
          objective: objective,
          percent: ((sellPointIncome * 100) / objective).toFixed(),
        });
      }

      setSellPointObjectives(sellPointObjectivesArray);

      const pointsOfSaleList = datosDashboardNew.pointOfSaleIncome.map((item) => item.pointOfSaleName);
      setSellPointList(pointsOfSaleList)
    }
  }, [datosDashboardNew]);
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} />
      <select 
        onChange={(e) => setSellPointSelected(e.target.value)}
        className="pointSalesSelect"
        >
        <option>Punto de venta</option>
        {sellPointList && sellPointList.map((item, index) => (
          <option key={index}>{item}</option>
        ))}
      </select>
      {datosDashboardNew &&
        datosDashboardNew.pointOfSaleIncome.some((item) => item.pointOfSaleName === sellPointSelected)
        ? (
          <div className="dataZoneContainer">
            <div className="dataZoneRowGraph">
              {parseFloat(datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif) !== undefined &&
                parseFloat(datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent) !==
                undefined && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('ingresosTotales')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {sellPointSelected &&
                            datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected) &&
                            formatNumber(datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)
                          }€

                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseInt(sellPointSelected && formatNumber(datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) >
                          parseInt(sellPointSelected && datosDashboardNew.prePointOfSaleIncome.length > 0 && datosDashboardNew.prePointOfSaleIncome.length > 0 && formatNumber(datosDashboardNew.prePointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(sellPointSelected && formatNumber(datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) >
                                parseInt(sellPointSelected && datosDashboardNew.prePointOfSaleIncome.length > 0 && formatNumber(datosDashboardNew.prePointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(
                                      (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif)
                                    )}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif)
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {formatNumber(datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif)}€
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent)}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            {parseFloat(datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif) &&
                              parseFloat(
                                (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent)
                              ) && (
                                <div className="dataZoneCardItemText">
                                  {parseInt(sellPointSelected && formatNumber(datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) >
                                    parseInt(sellPointSelected && datosDashboardNew.prePointOfSaleIncome.length > 0 && formatNumber(datosDashboardNew.prePointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome)) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {formatNumber(
                                          (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif)
                                        )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(
                                          (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent)
                                        )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {formatNumber(
                                          (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).dif)
                                        )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(
                                          (datosDashboardNew.pointOfSaleIncomeDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent)
                                        )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('ventasPerdiodoAnterior')}:{" "}
                      {formatNumber(sellPointSelected && datosDashboardNew.prePointOfSaleIncome.length > 0 && formatNumber(datosDashboardNew.prePointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome))}€
                    </p>
                  </div>
                )}

              {datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).dif !== undefined &&
                datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent !== undefined && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('unidadesVendidas')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {sellPointSelected && formatNumber(datosDashboardNew.pointOfSaleUnits.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleUnits)}
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {datosDashboardNew.totalHotelUnits >
                          datosDashboardNew.preTotalHotelUnits ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>

                            <div className="dataZoneCardItemText">
                              {parseInt(datosDashboardNew.totalHotelUnits) >
                                parseInt(datosDashboardNew.preTotalHotelUnits) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(
                                      datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).dif
                                    )}
                                    Uds
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).dif}Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(datosDashboardNew.hotalHotelUnits) >
                                parseInt(datosDashboardNew.preTotalHotelUnits) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).dif}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).dif}Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      datosDashboardNew.pointOfSaleUnitsDif.find((item) => item.pointOfSaleName === sellPointSelected).difPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('unidadesVendidasPeriodoAnterior')}:{" "}
                      {formatNumber(datosDashboardNew.preTotalHotelUnits)}Uds
                    </p>
                  </div>
                )}
              {parseFloat(datosDashboardNew.hotelStancesDif) &&
                datosDashboardNew.hotelStancesDifPercent && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>{changeWord('estancias')}</p>
                    </div>
                    <div className="dataZoneCardItemGroupFirst">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {formatNumber(datosDashboardNew.hotelStances)}
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {datosDashboardNew.hotelStances >
                          datosDashboardNew.preHotelStances ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(datosDashboardNew.hotelStances) >
                                parseInt(datosDashboardNew.preotelStances) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(datosDashboardNew.hotelStancesDif)}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      datosDashboardNew.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {formatNumber(datosDashboardNew.hotelStancesDif)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      datosDashboardNew.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {datosDashboardNew.hotelStances &&
                                parseInt(datosDashboardNew.hotelStances) >
                                parseInt(datosDashboardNew.preHotelStances) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(datosDashboardNew.hotelStancesDif)}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(
                                      datosDashboardNew.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {datosDashboardNew.hotelStancesDif &&
                                      datosDashboardNew.hotelStancesDif}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(
                                      datosDashboardNew.hotelStancesDifPercent
                                    )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      {changeWord('estanciasPeriodoAnterior')}:{" "}
                      {formatNumber(datosDashboardNew.preHotelStances)}
                    </p>
                  </div>
                )}
            </div>
            <div className="dataZoneRowGraph">
              <div className="sellPointsIncomeVsObjective">
                <div className="kpis">
                  {datosDashboardNew.incomePerStaffDif !== undefined &&
                    datosDashboardNew.incomePerStaffDifPercent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ventasPorEmpleado')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {sellPointSelected && formatNumber((datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected).pointOfSaleIncome) / datosDashboardNew.sellPointStaff.find((item) => item.sellPointName).sellPointStaff)}€
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {datosDashboardNew.incomePerStaff >
                              datosDashboardNew.preIncomePerStaff ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(datosDashboardNew.incomePerStaff) >
                                    parseInt(datosDashboardNew.preIncomePerStaff) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.incomePerStaffDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.incomePerStaffDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.incomePerStaffDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.incomePerStaffDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {datosDashboardNew.incomePerStaff &&
                                    parseInt(datosDashboardNew.incomePerStaff) >
                                    parseInt(datosDashboardNew.preIncomePerStaff) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.incomePerStaffDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.incomePerStaffDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.incomePerStaffDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.incomePerStaffDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStaffDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ventasEmpleadoPeriodoAnterior')}:{" "}
                          {datosDashboardNew.preIncomePerStaff &&
                            formatNumber(datosDashboardNew.preIncomePerStaff)}
                          €
                        </p>
                      </div>
                    )}

                  {datosDashboardNew.ticketAverageDif !== undefined &&
                    datosDashboardNew.ticketAverageDifPercent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ticketMedio')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {sellPointSelected && (() => {
                                const incomeItem = datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected);
                                const unitItem = datosDashboardNew.pointOfSaleUnits.find((item) => item.pointOfSaleName === sellPointSelected);

                                // Asegurarse de que ambos items existen y tienen valores válidos antes de hacer la división
                                if (incomeItem && unitItem && unitItem.pointOfSaleUnits !== 0) {
                                  const income = incomeItem.pointOfSaleIncome;
                                  const units = unitItem.pointOfSaleUnits;
                                  const result = income / units;
                                  const formattedResult = formatNumber(result)
                                  return `${formattedResult} €`;
                                }

                                return "N/A"; // Si no se encuentra algún dato, mostrar 'N/A'
                              })()}


                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {datosDashboardNew.ticketAverage >
                              datosDashboardNew.preTicketAverage ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(datosDashboardNew.ticketAverage) >
                                    parseInt(datosDashboardNew.preTicketAverage) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {datosDashboardNew.ticketAverage &&
                                    parseInt(datosDashboardNew.ticketAverage) >
                                    parseInt(datosDashboardNew.preTicketAverage) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.ticketAverageDif &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.ticketAverageDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.ticketAverageDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                          {datosDashboardNew.preTicketAverage &&
                            formatNumber(datosDashboardNew.preTicketAverage)}
                          €
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="sellPointsIncomeVsObjective">
                <div className="kpis kpisMobile">
                  {datosDashboardNew.incomePerStanceDif &&
                    datosDashboardNew.incomePerStanceDifPercent && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('ingresoPorEstancia')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {datosDashboardNew.incomePerStance &&
                                formatNumber(datosDashboardNew.incomePerStance)}
                              €
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {parseFloat(datosDashboardNew.incomePerStance) >
                              parseFloat(datosDashboardNew.preIncomePerStance) ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseFloat(datosDashboardNew.incomePerStance) >
                                    parseFloat(
                                      datosDashboardNew.preIncomePerStance
                                    ) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.incomePerStanceDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.incomePerStanceDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.incomePerStanceDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.incomePerStanceDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {datosDashboardNew.incomePerStance &&
                                    parseInt(datosDashboardNew.incomePerStance) >
                                    parseInt(
                                      datosDashboardNew.preIncomePerStance
                                    ) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.incomePerStanceDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.incomePerStanceDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.incomePerStanceDif &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.incomePerStanceDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.incomePerStanceDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('ticketMedioPeriodoAnterior')}:{" "}
                          {datosDashboardNew.preIncomePerStance &&
                            formatNumber(datosDashboardNew.preIncomePerStance)}
                          €
                        </p>
                      </div>
                    )}
                  {datosDashboardNew.averageItemDif &&
                    datosDashboardNew.averageItemDifPercent && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>{changeWord('precioPromedioVendido')}</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {sellPointSelected && (() => {
                                const incomeItem = datosDashboardNew.pointOfSaleIncome.find((item) => item.pointOfSaleName === sellPointSelected);
                                const unitItem = datosDashboardNew.pointOfSaleUnits.find((item) => item.pointOfSaleName === sellPointSelected);

                                // Asegurarse de que ambos items existen y tienen valores válidos antes de hacer la división
                                if (incomeItem && unitItem && unitItem.pointOfSaleUnits !== 0) {
                                  const income = incomeItem.pointOfSaleIncome;
                                  const units = unitItem.pointOfSaleUnits;
                                  const result = income / units;
                                  const formattedResult = formatNumber(result)
                                  return `${formattedResult} €`;
                                }

                                return "N/A"; // Si no se encuentra algún dato, mostrar 'N/A'
                              })()}
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {parseFloat(datosDashboardNew.averageSellItem) >
                              parseFloat(datosDashboardNew.averageItemSetted) ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(datosDashboardNew.averageSellItem) >
                                    parseInt(datosDashboardNew.averageItemSetted) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.averageItemDif &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.averageItemDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.averageItemDif &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.averageItemDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {datosDashboardNew.averageSellItem &&
                                    parseInt(datosDashboardNew.averageSellItem) >
                                    parseInt(datosDashboardNew.averageItemSetted) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {datosDashboardNew.averageItemDif &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDif
                                          )}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {datosDashboardNew.averageItemDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {datosDashboardNew.averageItemDif &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDif
                                          )}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {datosDashboardNew.averageItemDifPercent &&
                                          formatNumber(
                                            datosDashboardNew.averageItemDifPercent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          {changeWord('precioPromedioEstablecido')}:{" "}
                          {datosDashboardNew.averageItemSetted &&
                            formatNumber(datosDashboardNew.averageItemSetted)}
                          €
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="dataZoneRowGraph">
              <div className="sellPointsIncomeVsObjective">
                <div className="dataZoneCardTitle">
                  {!datosDashboardNew.allHotels ? (
                    <div className="contributionTitle">
                      <p>{changeWord('produccionPorPuntoDeVenta')}</p>
                      <p>{changeWord('ratioDeContribucion')}</p>
                    </div>
                  ) : (
                    <div className="contributionTitle">
                      <p>{changeWord('produccionPorHotel')}</p>
                      <p>{changeWord('ratioDeContribucion')}</p>

                    </div>
                  )}
                </div>
                {sellPointObjectives &&
                  sellPointObjectives.length > 0 &&
                  sellPointObjectives
                    .filter((item) => item.sellPointName === sellPointSelected)
                    .map((item, index) => (
                      <div key={index} className="sellPointItem">
                        <p className="sellPointNameGraph">{item.sellPointName}</p>
                        <div className="graphContainer">
                          {graphArr.map((_, graphIndex) => (
                            <div
                              key={graphIndex}
                              className={
                                graphIndex < parseInt(item.percent)
                                  ? "graphON"
                                  : "graphOFF"
                              }
                            >
                              {graphIndex === 45 && (
                                <div className="textOverlay">
                                  {formatNumber(item.sellPointIncome)}€
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <p className="sellPointsData">
                          {formatNumber(
                            (item.sellPointIncome * 100) /
                            datosDashboardNew.totalHotelIncome
                          )}
                          %
                        </p>
                      </div>
                    ))}
              </div>


              <div className="sellPointsIncomeVsObjective">
                <div className="dataZoneCardTitle">
                  <div className="rankingTitle">
                    <p>{changeWord('rankingDeProductos')}</p>
                  </div>
                </div>
                <div className="rankingProductosAndSelector">
                  {datosDashboardNew.topLastProductsSellPoint &&
                    (
                      <TopLastGraphic
                        data1={
                          graphToShow === "top"
                            ? datosDashboardNew.topLastProductsSellPoint.find((item) => item.sellPoint === sellPointSelected).top
                            : graphToShow === "last"
                              ? datosDashboardNew.topLastProductsSellPoint.find((item) => item.sellPoint === sellPointSelected).last
                              : datosDashboardNew.topLastProductsSellPoint.find((item) => item.sellPoint === sellPointSelected).top
                        }
                      />
                    )}
                  <div className="rankingProductosSelector">
                    <p
                      onClick={() => setGraphToShow("top")}
                      className={
                        graphToShow === "top"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      top
                    </p>
                    <p
                      onClick={() => setGraphToShow("last")}
                      className={
                        graphToShow === "last"
                          ? "rankingProductosSelectorItem rankingProductosSelectedSelect"
                          : "rankingProductosSelectorItem"
                      }
                    >
                      last
                    </p>
                    <p
                      onClick={() => setAllProductsVisible(true)}
                      className="rankingProductosSelectorItem"
                    >
                      {changeWord('todos')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {allProductsVisible && (
              <AllProducts
                setAllProductsVisible={setAllProductsVisible}
                data={datosDashboardNew.topLastProductsSellPoint.find((item) => item.sellPoint === sellPointSelected).all}
                title={hotelData.hotelName}
              />
            )}
          </div>
        ) : (
          <div className="noData">
            <h1>SIN DATOS</h1>
          </div>
        )}
    </div>
  );
};

export default SellPointsView;
