import dataGet from "../../Shared/API/dataGet";

const getAllHotelsData = async (groupData, dates, periodicity, setDatosDashboardNew, setHotelToShow, hotelData) => {
  console.log('GROUP DATA', groupData)
  if (groupData && groupData.length > 0) {
    const datosDashboardAllHotels = [];

    // Crea un arreglo de promesas
    const promises = groupData.map(async (item) => {
      const thisHotelId = item._id;
      return dataGet.getFBData(dates, thisHotelId, false, item.idHotelGroup, periodicity)
        .then((hotelDataResponse) => {
          datosDashboardAllHotels.push(hotelDataResponse.datosDashboard);
        });
    });

    // Espera a que todas las promesas se resuelvan
    await Promise.all(promises)

    const pointOfSaleYearObjectives_all = [];
    const pointOfSaleMonthObjectives_all = [];
    const pointOfSaleIncome_all = [];
    const pointOfSaleUnits_all = [];
    const sellPointStaff_all = [];
    const topLastProductsSellPoint_all = [];
    const thisHotelStaff = [];
    const thisAverageSellItem = [];
    const thisAverageItemSetted = [];

    for (const hotel of datosDashboardAllHotels) {
      const hotelName = hotel.hotelName;
      const hotelId = hotel.hotelId;
      const hotelYearObjective = hotel.pointOfSaleYearObjective.reduce((acc, obj) => acc + parseFloat(obj.objective), 0);
      const hotelMonthObjective = hotel.pointOfSaleMonthObjective.reduce((acc, obj) => acc + parseFloat(obj.objective), 0);
      const hotelStaff = hotel.sellPointStaff.reduce((acc, obj) => acc + parseFloat(obj.sellPointStaff), 0);
      thisHotelStaff.push(hotelStaff);
      pointOfSaleYearObjectives_all.push({ objective: hotelYearObjective, sellPointName: hotelName, sellPointCode: hotelId });
      pointOfSaleMonthObjectives_all.push({ objective: hotelMonthObjective, sellPointName: hotelName, sellPointCode: hotelId });
      pointOfSaleIncome_all.push({ pointOfSaleIncome: hotel.totalHotelIncome, pointOfSaleName: hotelName });
      pointOfSaleUnits_all.push({ pointOfSaleUnits: hotel.totalHotelUnits, pointOfSaleName: hotelName });
      sellPointStaff_all.push({ sellPointName: hotelName, sellPointStaff: hotelStaff });
      topLastProductsSellPoint_all.push({ sellPoint: hotelName, top: hotel.topLastProductsHotel.top, last: hotel.topLastProductsHotel.last, all: hotel.topLastProductsHotel.all, totalUnitsSum: hotel.topLastProductsHotel.totalUnitsSum });
      thisAverageSellItem.push(hotel.averageSellItem);
      thisAverageItemSetted.push(hotel.averageItemSetted);
    }

    const totalHotelIncome = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.totalHotelIncome), 0);
    const preTotalHotelIncome = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.preTotalHotelIncome), 0);
    const totalHotelIncomeDif = preTotalHotelIncome - totalHotelIncome;
    const totalHotelIncomeDifPercent = preTotalHotelIncome !== 0 ? (totalHotelIncome * 100) / preTotalHotelIncome : 100;

    const totalHotelUnits = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.totalHotelUnits), 0);
    const preTotalHotelUnits = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.preTotalHotelUnits), 0);
    const totalHotelUnitsDif = totalHotelUnits - preTotalHotelUnits;
    const totalHotelUnitsDifPercent = preTotalHotelUnits !== 0 ? (totalHotelUnits * 100) / preTotalHotelUnits : 100;
    const hotelStances = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.hotelStances), 0);
    const preHotelStances = datosDashboardAllHotels.reduce((acc, obj) => acc + parseFloat(obj.preHotelStances), 0);
    const hotelStancesDif = hotelStances - preHotelStances;
    const hotelStancesDifPercent = preHotelStances !== 0 ? (hotelStances * 100) / preHotelStances : 0;

    const groupStaff = thisHotelStaff.reduce((acc, obj) => acc + parseFloat(obj), 0)
    const incomePerStaff = groupStaff !== 0 ? totalHotelIncome / groupStaff : 0;
    const preIncomePerStaff = groupStaff !== 0 ? preTotalHotelIncome / groupStaff : 0;
    const incomePerStaffDif = incomePerStaff - preIncomePerStaff;
    const incomePerStaffDifPercent = preIncomePerStaff !== 0 ? (incomePerStaff * 100) / preIncomePerStaff : 100;
    const ticketAverage = totalHotelUnits !== 0 ? totalHotelIncome / totalHotelUnits : 0;
    const preTicketAverage = preTotalHotelUnits !== 0 ? preTotalHotelIncome / preTotalHotelUnits : 0;
    const ticketAverageDif = ticketAverage - preTicketAverage;
    const ticketAverageDifPercent = preTicketAverage !== 0 ? (ticketAverage * 100) / preTicketAverage : 100;

    const incomePerStance = hotelStances !== 0 ? totalHotelIncome / hotelStances : 0;
    const preIncomePerStance = preHotelStances !== 0 ? preTotalHotelIncome / preHotelStances : 0;
    const incomePerStanceDif = incomePerStance - preIncomePerStance;
    const incomePerStanceDifPercent = preIncomePerStance !== 0 ? (incomePerStance * 100) / preIncomePerStance : 100;

    const averageSellItemSum = thisAverageSellItem.reduce((acc, obj) => acc + parseFloat(obj), 0);
    const averageSellItem = averageSellItemSum / thisAverageSellItem.length;

    const averageItemSettedSum = thisAverageItemSetted.reduce((acc, obj) => acc + parseFloat(obj), 0);
    const averageItemSetted = averageItemSettedSum / thisAverageItemSetted.length;
    const averageItemDif = averageItemSetted - averageSellItem;
    const averageItemDifPercent = averageItemSetted !== 0 ? (averageSellItem * 100) / averageItemSetted : 100;

    const thisTopLastProductsSellPoint_all = []
    for (const objeto of topLastProductsSellPoint_all) {
      thisTopLastProductsSellPoint_all.push(objeto.all)
    }
    const thisTopLastProductsSellPoint_allFlatted = thisTopLastProductsSellPoint_all.flat();
    thisTopLastProductsSellPoint_allFlatted.sort((a, b) => b.income - a.income)
    const thisTop = thisTopLastProductsSellPoint_allFlatted.slice(0, 5);
    const thisLast = thisTopLastProductsSellPoint_allFlatted.slice(-5)
    const topLastProductsHotel = { top: thisTop, last: thisLast, all: thisTopLastProductsSellPoint_allFlatted }

    const objToShow = {
      pointOfSaleYearObjective: pointOfSaleYearObjectives_all,
      pointOfSaleMonthObjective: pointOfSaleMonthObjectives_all,
      pointOfSaleIncome: pointOfSaleIncome_all,
      pointOfSaleUnits: pointOfSaleUnits_all,
      totalHotelIncome: totalHotelIncome.toFixed(2),
      preTotalHotelIncome: preTotalHotelIncome.toFixed(2),
      totalHotelIncomeDif: totalHotelIncomeDif.toFixed(2),
      totalHotelIncomeDifPercent: totalHotelIncomeDifPercent.toFixed(0),
      totalHotelUnits: totalHotelUnits.toFixed(0),
      preTotalHotelUnits: preTotalHotelUnits.toFixed(0),
      totalHotelUnitsDif: totalHotelUnitsDif.toFixed(0),
      totalHotelUnitsDifPercent: totalHotelUnitsDifPercent.toFixed(0),
      hotelStances: hotelStances.toFixed(0),
      preHotelStances: preHotelStances.toFixed(0),
      hotelStancesDif: hotelStancesDif.toFixed(0),
      hotelStancesDifPercent: hotelStancesDifPercent.toFixed(0),
      incomePerStaff: incomePerStaff.toFixed(2),
      preIncomePerStaff: preIncomePerStaff.toFixed(2),
      incomePerStaffDif: incomePerStaffDif.toFixed(2),
      incomePerStaffDifPercent: incomePerStaffDifPercent.toFixed(0),
      ticketAverage: ticketAverage.toFixed(2),
      preTicketAverage: preTicketAverage.toFixed(2),
      ticketAverageDif: ticketAverageDif.toFixed(2),
      ticketAverageDifPercent: ticketAverageDifPercent.toFixed(0),
      incomePerStance: incomePerStance.toFixed(2),
      preIncomePerStance: preIncomePerStance.toFixed(2),
      incomePerStanceDif: incomePerStanceDif.toFixed(2),
      incomePerStanceDifPercent: incomePerStanceDifPercent.toFixed(0),
      averageSellItem: averageSellItem.toFixed(2),
      averageItemSetted: averageItemSetted.toFixed(2),
      averageItemDif: averageItemDif.toFixed(2),
      averageItemDifPercent: averageItemDifPercent.toFixed(0),
      topLastProductsHotel: topLastProductsHotel,
      topLastProductsSellPoint: topLastProductsSellPoint_all,
      sellPointStaff: sellPointStaff_all,
    };

    if (setDatosDashboardNew && setHotelToShow) {
      setDatosDashboardNew(objToShow);
      setHotelToShow(hotelData.idHotelGroup.name);
    }

    // Coloca el console.log aquí
    const result = { income: objToShow.pointOfSaleIncome };
    console.log(result)
    return result


  }
};

export default getAllHotelsData;
