// BarChart.jsx
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importa el plugin de etiquetas

// Registramos los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Registramos el plugin de etiquetas
);

const backGroundColorGraph = [
  'rgba(246, 94, 10, 1)',    // Color 2
  'rgba(204, 0, 102, 1)',    // Color 1
  'rgba(34, 139, 34, 1)',    // Color 3
  'rgba(10, 117, 198, 1)',   // Color 4
  'rgba(169, 169, 169, 1)',  // Color 5
  'rgba(255, 165, 0, 1)',    // Color 6
  'rgba(75, 0, 130, 1)',     // Color 7
  'rgba(220, 20, 60, 1)',    // Color 8
  'rgba(50, 205, 50, 1)',    // Color 9
  'rgba(255, 215, 0, 1)'     // Color 10
]

const RankingHotelsGraph = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // O un loading spinner
  }


  // Extraemos los nombres y los ingresos totales de los puntos de venta
  const labels = data.map((item) => item.pointOfSaleName);
  const totalIncomes = data.map((item) => item.pointOfSaleIncome);
  const allTotalIncomes = totalIncomes.reduce((acc, item) => acc + parseFloat(item), 0);

  // Configuración de los datos para el gráfico
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Income",
        data: totalIncomes,
        backgroundColor: backGroundColorGraph,
        borderColor: backGroundColorGraph,
        borderWidth: 1,
      },
    ],
  };

  // Opciones de configuración del gráfico
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Desactiva la leyenda
      },
      title: {
        display: false,
        text: "Total Income by Sell Point",
      },
      // Configuración para mostrar las etiquetas encima de las barras
      datalabels: {
        display: true,  // Activa la visualización de las etiquetas
        color: "black", // Color de la etiqueta
        anchor: "end",  // Alineación en el extremo de la barra
        align: "top",   // Alineación en la parte superior de la barra
        font: {
          size: 25,
          weight: 'bold',
        },
        formatter: (value) => {
          const percentage = ((value * 100) / allTotalIncomes).toFixed(2); // Cálculo del porcentaje
          return `${percentage}%`; // Mostramos el porcentaje
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Hotel",
        },
        ticks: {
          font: {
            size: 16,
            weight: 'bold'
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Ingreso",
        },
        beginAtZero: true,
        suggestedMax: Math.max(...totalIncomes) * 1.2,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default RankingHotelsGraph;
