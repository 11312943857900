const frontLanguages = {
  incentivos: {
    es: "Incentivos",
    en: "Incentives",
  },
  productosUpselling: {
    es: "Productos upselling",
    en: "Upselling products",
  },
  productosCrosselling: {
    es: "Productos cros selling",
    en: "Cros selling products",
  },
  objetivos: {
    es: "Objetivos",
    en: "Objectives",
  },
  historicos: {
    es: "Históricos",
    en: "Historics",
  },
  reportes: {
    es: "Reportes",
    en: "Reports",
  },
  materialDidactico: {
    es: "Material didáctico",
    en: "Teaching material",
  },
  campusOnline: {
    es: "Campus online",
    en: "Online campus",
  },
  datos: {
    es: "DATOS",
    en: "DATA",
  },
  registroDiario: {
    es: "REGISTRO DIARIO",
    en: "DAILY REGISTER",
  },
  informacionDiariaRequerida: {
    es: "INFORMACIÓN DIARIA REQUERIDA",
    en: "DAILY REQUESTED INFORMATION",
  },
  empleados: {
    es: "Empleados",
    en: "Employeds",
  },
  productos: {
    es: "Productos",
    en: "Products",
  },
  puntosDeVenta: {
    es: "Puntos de venta",
    en: "Sell points",
  },
  hoteles: {
    es: "Hoteles",
    en: "Hotels",
  },
  habitaciones: {
    es: "Habitaciones",
    en: "Rooms",
  },
  crosseling: {
    es: 'cross selling',
    en: 'cross selling'
  },


  //TOP_NAVBAR

  grupo: {
    es: "GRUPO",
    en: "GROUP",
  },
  hotel: {
    es: "HOTEL",
    en: "HOTEL",
  },
  departamentos: {
    es: "Departamentos",
    en: "Departments",
  },
  periodicidad: {
    es: "PERIODICIDAD",
    en: "PERIODICITY",
  },
  todosLosHoteles: {
    es: "Todos los hoteles",
    en: "All hotels",
  },
  diario: {
    es: "DIARIO",
    en: "DAILY",
  },
  semanal: {
    es: "SEMANAL",
    en: "WTD",
  },
  mensual: {
    es: "MENSUAL",
    en: "MTD",
  },
  anual: {
    es: "ANUAL",
    en: "YTD",
  },
  mes: {
    es: "mes",
    en: "month",
  },
  año: {
    es: "año",
    en: "year",
  },
  enero: {
    es: "enero",
    en: "january",
  },
  febrero: {
    es: "febrero",
    en: "february",
  },
  marzo: {
    es: "marzo",
    en: "march",
  },
  abril: {
    es: "abril",
    en: "april",
  },
  mayo: {
    es: "mayo",
    en: "may",
  },
  junio: {
    es: "junio",
    en: "june",
  },
  julio: {
    es: "julio",
    en: "july",
  },
  agosto: {
    es: "agosto",
    en: "august",
  },
  septiembre: {
    es: "septiembre",
    en: "september",
  },
  octubre: {
    es: "octubre",
    en: "october",
  },
  noviembre: {
    es: "noviembre",
    en: "november",
  },
  diciembre: {
    es: "diciembre",
    en: "december",
  },
  hola: {
    es: "Hola",
    en: "Hi",
  },
  miPerfil: {
    es: "mi perfil",
    en: "my profile",
  },
  configuracionDePerfil: {
    es: "configuración de perfil",
    en: "profile settings",
  },

  del: {
    es: "del",
    en: "from",
  },
  al: {
    es: "al",
    en: "to",
  },

  //DASHBOARD
  ingresosTotales: {
    es: "Ingresos totales",
    en: "Total income",
  },
  unidadesVendidas: {
    es: "Unidades vendidas",
    en: "Sell units",
  },
  estancias: {
    es: "Estancias",
    en: "Stances",
  },
  ventasPorEmpleado: {
    es: "Ventas por empleado",
    en: "Income per staff",
  },
  ticketMedio: {
    es: "Ticket medio",
    en: "Ticket average",
  },
  ingresoPorEstancia: {
    es: "Ingreso por estancia",
    en: "Income per stance",
  },
  precioPromedioVendido: {
    es: "Precio promedio vendido",
    en: "Average sell income",
  },
  precioPromedioEstablecido: {
    es: "Precio promedio establecido",
    en: "Established average price"
  },
  produccionPorPuntoDeVenta: {
    es: "Producción por punto de venta",
    en: "Sell point income",
  },
  produccionPorHotel: {
    es: "Producción por hotel",
    en: "Hotel income",
  },
  ratioDeContribucion: {
    es: "Ratio de contribución",
    en: "Contribution ratio",
  },
  rankingDeProductos: {
    es: "Ranking de productos",
    en: "Products ranking",
  },
  todos: {
    es: "todos",
    en: "all",
  },
  ventasPerdiodoAnterior: {
    es: "ventas periodo anterior",
    en: "previous periode sales",
  },
  unidadesVendidasPeriodoAnterior: {
    es: "uds.vendidas periodo anterior",
    en: "units sold previous perdiod",
  },
  estanciasPeriodoAnterior: {
    es: "estancias periodo anterior",
    en: "previous period stays",
  },
  ventasEmpleadoPeriodoAnterior: {
    es: "ventas/empleado periodo anterior",
    en: "sales/employee previous period",
  },
  ticketMedioPeriodoAnterior: {
    es: "ticket medio periodo anterior",
    en: "average ticket previous period",
  },
  ingresoPorEstanciaPeriodoAnterior: {
    es: "Ingreso por estancia periodo anterior",
    en: "income per stay previous period",
  },



  ingresos: {
    es: "Ingresos",
    en: "Income",
  },
  unidades: {
    es: "Unidades",
    en: "Units",
  },
  productosVendidos: {
    es: "Productos vendidos",
    en: "Products sold",
  },
  totales: {
    es: "Totales",
    en: "Totals",
  },
  descargar: {
    es: "Descargar",
    en: "Download",
  },
  formato: {
    es: "Formato",
    en: "Format",
  },
  rankingDePuntosDeVenta: {
    es: "Ranking de pntos de venta",
    en: "Ranking of points of sale",
  },
  productosControlados: {
    es: "Productos controlados",
    en: "Controlled products",
  },
  productosNuevos: {
    es: "Productos nuevos",
    en: "New products",
  },
};

export default frontLanguages;
