import React, { useState } from "react";
import "./LeftNavbar.css";
import desplegable from "../../Images/Icons/desplegable.png";
import { useAppContext } from "../../Shared/Context/AppContext";
import useLanguages from "../../Shared/languages/setLanguages";

const LeftNavbar = () => {
  const { setDataZoneView, onlyOneHotel, dataZoneView } =
    useAppContext();
  const changeWord = useLanguages()
  const [rankingItemsVisible, setRankingItemsVisible] = useState(false);

  const fnChangeRanking = (value) => {
    if (value === "Productos") {
      setDataZoneView("rankingProductos");
    }
    if (value === "Puntos de venta") {
      setDataZoneView("rankingSellPoints");
    }
    if (value === "Hoteles") {
      setDataZoneView("hoteles");
    }
    if (value === "Empleados") {
      setDataZoneView("rankingEmpleados");
    }
  };
  return (
    <div className="leftNavbar">
      <div
        className={
          dataZoneView !== "dashboard"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("dashboard");
          }}
        >
          Dashboard
        </p>
      </div>
      <div
        className={
          rankingItemsVisible
            ? "leftNavbarItem  rankingSelected"
            : "leftNavbarItem"
        }
      >
        <p
          id="menu"
          onClick={() => setRankingItemsVisible(!rankingItemsVisible)}
        >
          Ranking
        </p>
        <div
          className="flechaDesplegable"
          onClick={() => setRankingItemsVisible(!rankingItemsVisible)}
        >
          <img src={desplegable} alt="desplegable" />
        </div>
      </div>

      {rankingItemsVisible && (
        <div className="leftNavbarItemGroup" id="firtsMenu">
          <p
            className={
              dataZoneView !== "rankingEmpleados"
                ? "leftNavbarItemGroupItem"
                : "leftNavbarItemGroupItem itemSelected"
            }
            id="firtsMenu"
            onClick={() => fnChangeRanking("Empleados")}
          >
            {changeWord('empleados')}
          </p>
          <p
            className={
              dataZoneView !== "rankingProductos"
                ? "leftNavbarItemGroupItem"
                : "leftNavbarItemGroupItem itemSelected"
            }
            onClick={() => fnChangeRanking("Productos")}
          >
            {changeWord('productos')}
          </p>
          <p
            className={
              dataZoneView !== "rankingSellPoints"
                ? "leftNavbarItemGroupItem"
                : "leftNavbarItemGroupItem itemSelected"
            }
            onClick={() => fnChangeRanking("Puntos de venta")}
          >
            {changeWord('puntosDeVenta')}
          </p>
          {!onlyOneHotel && (
            <p
              className={
                dataZoneView !== "hoteles"
                  ? "leftNavbarItemGroupItem"
                  : "leftNavbarItemGroupItem itemSelected"
              }
              onClick={() => fnChangeRanking("Hoteles")}
            >
              {changeWord('hoteles')}
            </p>
          )}
        </div>
      )}
      <div
        className={
          dataZoneView !== "incentives"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("incentives");
          }}
        >
          {changeWord('incentivos')}
        </p>
      </div>

      <div
        className={
          dataZoneView !== "products"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("products");
          }}
        >
          {changeWord('productos')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "sellPoints"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("sellPoints");
          }}
        >
          {changeWord('puntosDeVenta')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "objectives"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("objectives");
          }}
        >
          {changeWord('objetivos')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "historics"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("historics");
          }}
        >
          {changeWord('historicos')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "reports"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("reports");
          }}
        >
          {changeWord('reportes')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "materialDidactico"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("materialDidactico");
          }}
        >
          {changeWord('materialDidactico')}
        </p>
      </div>
      <div
        className={
          dataZoneView !== "campusOnline"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("campusOnline");
          }}
        >
          {changeWord('campusOnline')}
        </p>
      </div>
      {/* <div
        className={
          dataZoneView !== "Ia"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("Ia");
          }}
        >
          IA
        </p>
      </div> */}
    </div>
  );
};

export default LeftNavbar;
