import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import "./FdDashboarView.css";
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import FdRankingEmpleadosGraph from "../../../Components/Graphics/FdRankingEmpleadosGraph";
import AllScreen from "../../../Components/AllScreen/AllScreen";


const rankinEmpleadosTest = [
  {
    name: "empleado1",
    upselling: {
      income: 50,
      units: 5,
    },
    crosselling: {
      income: 20,
      units: 3,
    },
  },
  {
    name: "empleado2",
    upselling: {
      income: 55,
      units: 4,
    },
    crosselling: {
      income: 25,
      units: 4,
    },
  },
  {
    name: "empleado3",
    upselling: {
      income: 40,
      units: 6,
    },
    crosselling: {
      income: 15,
      units: 10,
    },
  },
];

const getTotalIncome = (data) => {
  const days = data.groupedCheckins.flatMap((item) => item.checkins);
  const groupedByStaffId = days.reduce((acc, current) => {
    const { staffId } = current;

    if (!acc[staffId]) {
      acc[staffId] = [];
    }

    acc[staffId].push(current);

    return acc;
  }, {});

  const resultArray = Object.keys(groupedByStaffId).map((staffId) => {
    return {
      staffData: groupedByStaffId[staffId],
    };
  });

  const returnArr = [];
  for (const staff of resultArray) {
    const staffName = staff.staffData[0].staffName;
    const checkins = staff.staffData.flatMap((item) => item.checkins);
    const roomUpselling = checkins.reduce((acc, item) => {
      return acc + item.nights * item.increment;
    }, 0);
    const objToPush = {
      staffName: staffName,
      roomUpselling: roomUpselling,
    };
    returnArr.push(objToPush);
  }
};

let objectivesArray = Array.from({ length: 120 }, (_, i) => i);

const FdDashboarView = ({
  setDataToShow,
  setActualHotelData,
  setLoading,
  dates,
}) => {
  const { hotelData, periodicity, rol } = useAppContext();
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(true);
  const [objectiveType, setObjectiveType] = useState();
  const [vsName, setVsName] = useState("");
  const [allScreen, setAllScreen] = useState(false);

  useEffect(() => {
    if (hotelData && hotelData._id) {
      const idHotel = hotelData._id;
      const groupId = hotelData.idHotelGroup._id;
      const pdf = false;
      dataGet
        .getFDData(dates, idHotel, pdf, groupId)
        .then((response) => {
          setData(response);
          setActualHotelData(response);
        })
        .catch((error) => {
          console.error("error al obtener los datos:", error);
        });
    }
  }, [hotelData, dates]);

  useEffect(() => {
    let incomeTotal = 0;
    let exitData = false;
    if (periodicity) {
      if (periodicity === "1") {
        setVsName("dia");
      }
      if (periodicity === "5") {
        setVsName("semana");
      }
      if (periodicity === "10") {
        setVsName("mes");
      }
      if (periodicity === "100") {
        setVsName("año");
      }
    }

    if (data && data.data && periodicity) {
      if (parseInt(periodicity) === "1") {
        //diario

        if (data.data.periode.length > 0) {
          exitData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.periode);
      } else if (parseInt(periodicity) === "10") {
        if (data.data.MTD.length > 0) {
          exitData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.MTD);
      } else if (parseInt(periodicity) === "5") {
        if (data.data.WTD.length > 0) {
          exitData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.MTD);
      } else if (parseInt(periodicity) === "100") {
        if (data.data.YTD) {
          exitData = true;
        }
        setObjectiveType("anual");
        incomeTotal = getTotalIncome(data.data.YTD);
      }
    }
  }, [data, periodicity]);
  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" : 
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
       rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
    <AllScreen setAllScreen={setAllScreen} allScreen={allScreen}/>
      <div className="FdDashboardContainer">
        <div className="FdFirtsRow">
          <div className="FdFirtsRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Total ingresos</h1>
            </div>
            <div className="FDFirtsRowTargetData">
              <p className="FDtargetActualValue">1200€</p>
              <div className="FDtargetIcon">
                <img src={flechaVerde} alt="verde" />
              </div>
              <p className="FDtargetCompare">10%</p>
            </div>
            <div className="FDFirtsRowTargetVs">
              <p>{`vs ${vsName} anterior`}</p>
            </div>
          </div>
          <div className="FdFirtsRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Unidades vendidas</h1>
            </div>
            <div className="FDFirtsRowTargetData">
              <p className="FDtargetActualValue">500</p>
              <div className="FDtargetIcon">
                <img src={flechaVerde} alt="verde" />
              </div>
              <p className="FDtargetCompare">10%</p>
            </div>
            <div className="FDFirtsRowTargetVs">
              <p>{`vs ${vsName} anterior`}</p>
            </div>
          </div>
          <div className="FdFirtsRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Total checkins</h1>
            </div>
            <div className="FDFirtsRowTargetDataOnlyOne">
              <p className="FDtargetActualValue">132</p>
              {/* <div className="FDtargetIcon">
                <img src={flechaVerde} alt="verde" />
              </div>
              <p className="FDtargetCompare">10%</p> */}
            </div>
            {/* <div className="FDFirtsRowTargetVs">
              <p>{`vs ${vsName} anterior`}</p>
            </div> */}
          </div>
          <div className="FdFirtsRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Total estancias</h1>
            </div>
            <div className="FDFirtsRowTargetDataOnlyOne">
              <p className="FDtargetActualValue">400</p>
              {/* <div className="FDtargetIcon">
                <img src={flechaVerde} alt="verde" />
              </div> */}
              {/* <p className="FDtargetCompare">10%</p> */}
            </div>
            {/* <div className="FDFirtsRowTargetVs">
              <p>{`vs ${vsName} anterior`}</p>
            </div> */}
          </div>
          <div className="FdFirtsRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">% de ocupación</h1>
            </div>
            <div className="FDFirtsRowTargetDataOnlyOne">
              <p className="FDtargetActualValue">90%</p>
              {/* <div className="FDtargetIcon">
                <img src={flechaVerde} alt="verde" />
              </div> */}
              {/* <p className="FDtargetCompare">10%</p> */}
            </div>
            {/* <div className="FDFirtsRowTargetVs">
              <p>{`vs ${vsName} anterior`}</p>
            </div> */}
          </div>
        </div>
        <div className="FDsecondRow">
          <div className="FDsecondRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Ingreso por conceptos</h1>
            </div>
            <div className="FDSecondRowConcepts">
              <div>
                <p className="FDconceptsTitle">Upselling</p>
              </div>
              <div className="FDGraphObjectives">
              {objectivesArray &&
                  objectivesArray.length > 0 &&
                  objectivesArray.map((item, index) =>
                    index <= 30 ? (
                      <div key={index} className="graphON"></div>
                    ) : (
                      <div key={index} className="graphOFF"></div>
                    )
                  )}
                <p className="FDparticipationPercent">30% sobre objetivo</p>
              </div>
              <div>
                <p className="FDconceptsTitle">Crosselling</p>
              </div>
              <div className="FDGraphObjectives">
                {objectivesArray &&
                  objectivesArray.length > 0 &&
                  objectivesArray.map((item, index) =>
                    index <= 60 ? (
                      <div key={index} className="graphON"></div>
                    ) : (
                      <div key={index} className="graphOFF"></div>
                    )
                  )}

                <p className="FDparticipationPercent">60% sobre objetivo</p>
              </div>
            </div>
          </div>
          <div className="FDsecondRowTarget">
            <div className="FDFirtsRowTargetTitleContainer">
              <h1 className="FDFirtsRowTargetTitle">Ranking de empleados</h1>
            </div>
            <div className="FDSecondRowConceptsGraph">
            <FdRankingEmpleadosGraph
            dataSet={rankinEmpleadosTest}
            type='all'
            dashboard={true}
          />
            </div>
          </div>
        </div>
        <div className="FDThirdRow">
          <div className="FDThirdRowTitleContainer">
            <p className="FDThirdRowTitle">KPIs</p>
          </div>
          <div className="FDThirdRowTargetContainer">
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Ingreso / estancia</p>
              <p className="FDThirdRowTargetData"> 40€</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Ratio de conversión</p>
              <p className="FDThirdRowTargetTitle"> Upselling rooms</p>
              <p className="FDThirdRowTargetData"> 30€</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Ratio de conversión</p>
              <p className="FDThirdRowTargetTitle"> Crosseling</p>
              <p className="FDThirdRowTargetData"> 8%</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Promedio de</p>
              <p className="FDThirdRowTargetTitle"> ventas / unidades</p>
              <p className="FDThirdRowTargetData">25€</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Promedio de</p>
              <p className="FDThirdRowTargetTitle"> ventas / agente</p>
              <p className="FDThirdRowTargetData"> 7,5</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Suplemento medio por</p>
              <p className="FDThirdRowTargetTitle"> habitación vendida</p>
              <p className="FDThirdRowTargetData"> 12,53€</p>
            </div>
            <div className="FDThirdRowTarget">
              <p className="FDThirdRowTargetTitle"> Precio medio vendido</p>
              <p className="FDThirdRowTargetTitle"> de crosselling</p>
              <p className="FDThirdRowTargetData"> 23,65€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FdDashboarView;
