import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TopLastGraphic = ({ data1 }) => {
  // Crear etiquetas truncadas para el eje X
  const labelsTruncated = data1.map((item) => {
    let labelToShow = item.productName;
    if (labelToShow.length > 20) {
      labelToShow = labelToShow.substring(0, 17) + '...'; // Mostrar los primeros 20 caracteres seguido de "..."
    }
    return labelToShow;
  });

  // Crear etiquetas completas para mostrar en el tooltip
  const fullLabels = data1.map(item => item.productName);

  const data = {
    labels: labelsTruncated, // Usar etiquetas truncadas en el gráfico
    datasets: [
      {
        data: data1.map(item => (item.income).toFixed(2)),
        backgroundColor: 'rgba(34, 139, 34, 0.8)',
        borderColor: 'rgba(34, 139, 34, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value) {
            return value + '€';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // Mostrar el nombre completo en el tooltip
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return fullLabels[index]; // Mostrar el nombre completo del producto en el tooltip
          },
        },
      },
    },
    datalabels: {
      display: false,
    },
  };

  return <Bar data={data} options={options} />;
};

export default TopLastGraphic;
