import React, { useEffect, useState } from "react";
import "./RankingHotels.css";
import dataGet from "../../../Shared/API/dataGet";
import { useAppContext } from "../../../Shared/Context/AppContext";
import RankingHotelsGraph from "../../../Components/Graphics/RankingHotelesGraph";
import useLanguages from "../../../Shared/languages/setLanguages";
import AllScreen from "../../../Components/AllScreen/AllScreen";
import getAllHotelsData from "../../../Components/TopNavbar/GetAllHotelsData";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const RankingHotels = ({ dates }) => {
  const { rol, datosDashboardNew, hotelData, periodicity } = useAppContext();
  // const [data, setData] = useState(null);
  // const [hotelData, setHotelData] = useState(null);
  // const [hotelNames, setHotelNames] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [allScreen, setAllScreen] = useState(false)
  const changeWord = useLanguages();

  useEffect(() => {
    if (datosDashboardNew) {
      console.log(datosDashboardNew) 
      dataGet.hotelGroupId(hotelData.idHotelGroup._id).then((thisGroupData) => {
        getAllHotelDataComponent(thisGroupData);
      })
      
    }

  }, [datosDashboardNew])
  const getAllHotelDataComponent = async (recGroupData) => {
    try {
      const result = await getAllHotelsData(recGroupData, dates, periodicity, null, null, null);
      setDataToShow(result.income)
    } catch (error) {
      console.error('Error al obtener los datos de todos los hoteles:', error);
    }
  };

  const backGroundColorGraph = [
    'rgba(246, 94, 10, 1)',    // Color 2
    'rgba(204, 0, 102, 1)',    // Color 1
    'rgba(34, 139, 34, 1)',    // Color 3
    'rgba(10, 117, 198, 1)',   // Color 4
    'rgba(169, 169, 169, 1)',  // Color 5
    'rgba(255, 165, 0, 1)',    // Color 6
    'rgba(75, 0, 130, 1)',     // Color 7
    'rgba(220, 20, 60, 1)',    // Color 8
    'rgba(50, 205, 50, 1)',    // Color 9
    'rgba(255, 215, 0, 1)'     // Color 10
  ]

  return (
    <div className={rol !== 'ADMIN' && allScreen ? "dataZone allScreen" :
      rol !== 'ADMIN' && !allScreen ? 'dataZone' :
        rol === "ADMIN" && allScreen ? 'dataZoneAdmin allScreen' : 'dataZoneAdmin'}>
      <AllScreen setAllScreen={setAllScreen} allScreen={allScreen} />
      <div className="rankingHotelsContainer">
        <div className="rankingHotelsHotel">
          <p className="rankingTodosLosHotelsTitle">{changeWord('todosLosHoteles')}</p>
          {dataToShow && dataToShow.length > 0 && (
            <RankingHotelsGraph data={dataToShow} />
          )}
        </div>
        {/* {dataToShow &&
          dataToShow.length > 0 &&
          dataToShow.map((item, index) => (
            <div key={index} className="rankingHotelsHotel">
              <div className="rankinHotelsTitle">
                <p>{item.hotelName}</p>
                <p>{formatNumber(item.totalHotelIncome)}€</p>
              </div>

              <div>
                <div key={index} className="rankingHotelsGraph">
                  <RankingHotelsSellPoints
                    data={item.sellPointIncomes}
                    colors = {backGroundColorGraph[index]}
                   
                  />
                </div>
              </div>
            </div>
          ))} */}
      </div>
    </div>
  );
};

export default RankingHotels;
